.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.help {
  height: 100px;
  display: block;
  margin: auto;
  width: 200px;
}
