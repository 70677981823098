body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
